/** SHINY PROBABILITY LOGIC
 * For each method of shiny hunting, there are sometimes different ways to increase the odds of finding a shiny as you go through it.
 * Each of those will be calculated differently so odds can constantly be updated on screen
*/

/** METHOD IDs
 * As of November 8, 2020 the hunting methods are as follows:
 * 0 = Wild Encounter (Gen 8)
 * 1 = Masuda Method (Gen 6+)
 * 2 = S.O.S. Battles (Gen 7)
 * 3 = Wild Encounter (Gen 7)
 * 4 = Soft Resetting (Gen 6+)
 * 5 = Dynamax Adventures (Gen 8)
 */

class MethodValues {
	constructor(baseProb, baseValue, shinyProb, shinyValue) {
		this.baseProb = baseProb;
		this.baseValue = baseValue;
		this.shinyProb = shinyProb;
		this.shinyValue = shinyValue;
		Object.freeze(this);
	}
}

/**
 * A function that can be used to calculate the given shiny odds for any method being used
 * @param {number} methodId The unique identifier for which method is being used
 * @param {number} encounters The number of times a Pokémon has been encountered
 */
export const dynamicShinyOdds = (methodId, encounters) => {
	const chainCount = encounters % 255; // For SOS method only
	switch (methodId) {
		case 0:
			if (encounters < 50) return new MethodValues('1/4096', 1 / 4096, '1/1366', 1 / 1366);
			if (encounters >= 50 && encounters < 100) return new MethodValues('1/2048', 1 / 2048, '1/1024', 1 / 1024);
			if (encounters >= 100 && encounters < 200) return new MethodValues('1/1366', 1 / 1366, '1/820', 1 / 820);
			if (encounters >= 200 && encounters < 300) return new MethodValues('1/1024', 1 / 1024, '1/683', 1 / 683);
			if (encounters >= 300 && encounters < 500) return new MethodValues('1/820', 1 / 820, '1/586', 1 / 586);
			return new MethodValues('1/683', 1 / 683, '1/512', 1 / 512);
		case 1:
			return new MethodValues('1/683', 1 / 683, '1/512', 1 / 512);
		case 2:
			if (chainCount <= 10) return new MethodValues('1/4096', 1 / 4096, '1/1366', 1 / 4096);
			if (chainCount > 10 && chainCount <= 20) return new MethodValues('1/820', 1 / 812, '1/586', 1 / 586);
			if (chainCount > 20 && chainCount <= 30) return new MethodValues('1/455', 1 / 455, '1/373', 1 / 373);
			return new MethodValues('1/315', 1 / 315, '1/273', 1 / 273);
		case 5:
			return new MethodValues('1/300', 1 / 300, '1/100', 1 / 100);
		default:
			return new MethodValues('1/4096', 1 / 4096, '1/1366', 1 / 1366);
	}
};

export const areSameOdds = (originalOdds, newOdds) => {
	const originalKeys = Object.keys(originalOdds);
	const newKeys = Object.keys(newOdds);
	if (originalKeys.length !== newKeys.length) return false;
	for (let i = 0; i < originalKeys.length; i++) {
		if (!newKeys.includes(originalKeys[i])) return false;
		if (originalOdds[originalKeys[i]] !== newOdds[originalKeys[i]]) return false;
	}

	return true;
};

export const calculateBinomialProbability = (methodId, encounters, shinyCharm) => {
	const chainCount = encounters % 255; // For SOS method only

	let b = 0; // this is our cumulative binomial probability value
	// Easiest way to calculate the probability of AT LEAST one shiny is to subtract the probability of 0 shinies from 1
	// p(X>=1) = 1 - p(0) = 1 - (1 - odds) ^ encounters
	if (methodId === 0 || methodId === 2) {
		const breaks = methodId === 0 ? ([
			{ limit : 50, odds : new MethodValues('1/4096', 1 / 4096, '1/1366', 1 / 1366) },
			{ limit : 100, odds : new MethodValues('1/2048', 1 / 2048, '1/1024', 1 / 1024) },
			{ limit : 200, odds : new MethodValues('1/1366', 1 / 1366, '1/820', 1 / 820) },
			{ limit : 300, odds : new MethodValues('1/1024', 1 / 1024, '1/683', 1 / 683) },
			{ limit : 500, odds : new MethodValues('1/820', 1 / 820, '1/586', 1 / 586) },
			{ limit : 9999999, odds : new MethodValues('1/683', 1 / 683, '1/512', 1 / 512) },
		]) : ([
			{ limit : 10, odds : new MethodValues('1/4096', 1 / 4096, '1/1366', 1 / 1366) },
			{ limit : 20, odds : new MethodValues('1/820', 1 / 820, '1/586', 1 / 586) },
			{ limit : 30, odds : new MethodValues('1/455', 1 / 455, '1/373', 1 / 373) },
			{ limit : 254, odds : new MethodValues('1/315', 1 / 315, '1/273', 1 / 273) },
		]);
		let remain = methodId === 0 ? encounters : chainCount;
		for (let i = 0; i < breaks.length; i++) {
			const { limit, odds : { shinyValue, baseValue } } = breaks[i];
			let prevLimit = 0;
			if (i > 0) ({ limit : prevLimit } = breaks[i - 1]);
			const odds = shinyCharm ? shinyValue : baseValue;
			if (remain >= limit) {
				b += 1 - ((1 - odds) ** (limit - prevLimit));
				remain -= (limit - prevLimit);
			} else if (remain > 0) {
				b += 1 - ((1 - odds) ** remain);
				break;
			}
		}
	} else {
		let odds = shinyCharm ? 1 / 1366 : 1 / 4096;
		if (methodId === 1) odds = shinyCharm ? 1 / 512 : 1 / 683;
		else if (methodId === 5) odds = shinyCharm ? 1 / 100 : 1 / 300;
		b = 1 - ((1 - odds) ** encounters);
	}

	return (b * 100).toFixed(2);
};
