<template>
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col">
				<router-link
					:to="{ path: '/file', query: { gameId: $route.query.gameId, fileId: $route.query.fileId } }"
				>
					&lt; Back to file
				</router-link>
			</div>
		</div>
	</div>
	<div class="container-fluid" :class="{ invisible: Object.values(hunt).length === 0 }">
		<div class="row">
			<div class="col-12">
				<h2 class="mb-0">{{ hunt.pokemon }}</h2>
			</div>
			<div class="col-12 text-muted">
				{{ fileData.fileName }}
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12 col-sm-6 col-md-5 align-items-center">
				<img class="shiny-img" :src="hunt.imageUrl" :alt="`Shiny ${hunt.pokemon}`" />
			</div>
			<div class="col-12 col-sm-6 col-md-6 offset-md-1">
				<div class="row mb-2">
					<div class="col-12 text-center text-sm-left mt-3 mt-sm-0">
						<h4>{{ method.name }}</h4>
					</div>
					<div class="col-12" :class="{ 'strike': fileData.shinyCharm }">
						Base Probability: {{ method.baseProb }}
					</div>
					<div class="col-12" v-if="fileData.shinyCharm">
						<strong>Probability with Shiny Charm: {{ method.shinyProb }}</strong>
					</div>
				</div>
				<div class="row">
					<div class="col-6 offset-3 col-sm-5 offset-sm-0 col-md-4 form-group">
						<label for="encounters">{{ getEncounterText() }}</label>
						<input
							type="number"
							class="form-control"
							id="encounters"
							min="0"
							:disabled="hunt.huntStatus !== '01'"
							v-model="hunt.encounters"
						/>
					</div>
				</div>
				<div class="row" v-if="hunt.huntStatus === '01'">
					<div class="col-6 col-sm-5 col-md-4">
						<button type="button" class="btn btn-block btn-secondary" @click="stepValue(-1)">Decrease</button>
					</div>
					<div class="col-6 col-sm-5 col-md-4">
						<button type="button" class="btn btn-block btn-primary" @click="stepValue(1)">Increase</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-12" :class="{ 'd-none': hunt.huntStatus !== '01' }">
				Based on the binomial distribution, it is <strong>{{ binomProb }}%</strong>
				likely that you have already {{ getEncounterText(true) }} a shiny {{ hunt.pokemon }}.
			</div>
			<div class="col-12" :class="{ 'd-none': hunt.huntStatus !== '10' }">
				When you {{ getEncounterText(true) }} your shiny {{ hunt.pokemon }}, there was a
				<strong>{{ binomProb }}%</strong> chance that one had already appeared.
			</div>
			<div class="col-12" :class="{ 'd-none': hunt.huntStatus !== '11' }">
				When you gave up on this shiny hunt, there was a <strong>{{ binomProb }}%</strong>
				chance that you already {{ getEncounterText(true) }} a shiny {{ hunt.pokemon }}.
			</div>
		</div>
		<div class="row" v-if="hunt.huntStatus === '01'">
			<div class="col col-sm-5 col-md-4 col-lg-3">
				<button class="btn btn-danger btn-block" @click="hunt.huntStatus = '11'">
					Give Up
				</button>
			</div>
			<div class="col col-sm-5 offset-sm-2 col-md-4 offset-md-0 col-lg-3">
				<button class="btn btn-success btn-block" @click="hunt.huntStatus = '10'">
					Completed
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { getCurrentUser} from '../services/auth';
import { getGames, getFile, getHuntMethod, updateHuntInfo } from '../services/games';
import { dynamicShinyOdds, areSameOdds, calculateBinomialProbability } from '../oddsCalculation';

export default {
	data() {
		return {
			gameId: '',
			fileId: '',
			fileData: {},
			hunt: {},
			gameName: '',
			method: {
				name: '',
				baseProb   : '1/4096',
				baseValue  : 0.000244,
				shinyProb  : '1/1366',
				shinyValue : 0.000732,
			},
			binomProb: 0,
		}
	},
	mounted() {
		const { query : { gameId, fileId, huntId } } = this.$route;
		if (!gameId || !fileId || !huntId) {
			this.$router.replace({
				path: '/file',
				query: { gameId, fileId }
			});	
		} else {
			this.gameId = gameId;
			this.fileId = parseInt(fileId, 10);
			getGames((data) => {
				this.gameName = Object.entries(data).find(([id]) => id === gameId)[1].name
			});
			const user = getCurrentUser();
			getFile(user.uid, this.gameId, this.fileId, (file) => {
				if(!file || file instanceof Error) {
					this.$router.replace({ path: '/file', query: { gameId, fileId } });
				} else {
					this.fileData = Object.fromEntries(Object.entries(file).filter(([key]) => key !== 'hunts'));
					this.hunt = Object.values(file.hunts).find(hunt => hunt.huntId.toString() === huntId);
					getHuntMethod(this.hunt.methodId, (data) => {
						this.method.name = data.name;
					});
					if (this.hunt.huntStatus === '00') this.hunt.huntStatus = '01';
					this.updateOdds();
				}
			});
		}
	},
	watch: {
		'hunt.encounters': function(newValue, oldValue) {
			if (newValue && oldValue) this.updateInfo(this.updateOdds);
			if (newValue) {
				const { methodId, encounters } = this.hunt;
				this.binomProb = calculateBinomialProbability(methodId, encounters, this.fileData.shinyCharm)
			}
		},
		'hunt.huntStatus': function(oldStatus, newStatus) {
			if (oldStatus && newStatus) this.updateInfo(() => {
				this.$router.replace({
				path: '/file',
				query: { gameId: this.gameId, fileId: this.fileId }
			});	
			})
		},
	},
	methods: {
		getEncounterText(past = false) {
			if (past && this.hunt.methodId === 1) return 'hatched';
			if (past) return 'encountered';
			switch (this.hunt.methodId) {
				case 1:
					return 'Hatches';
				case 2:
					return 'Chain';
				case 4:
					return 'Resets';
				case 0:
				case 3:
				default:
					return 'Encounters'
			}
		},
		stepValue(increment) {
			const encountersInt = this.hunt.encounters !== '' ? parseInt(this.hunt.encounters, 10) : 0;
			if (increment < 0 && encountersInt === 0) return; // Ensure the counter doesn't go below 0
			if (increment > 0 && encountersInt === 9999999) return; // Cap the increment at 9999999
			this.hunt.encounters = encountersInt + increment;
		},
		updateInfo(callback) {
			const { huntStatus, encounters : currentEncounters, methodId } = this.hunt;
			const { shinyCharm } = this.fileData;
			const encounters = huntStatus === '10' && currentEncounters === 0 ? 1 : currentEncounters;
			const update = {
				huntStatus,
				encounters,
				updated: new Date().getTime(),
			};
			if (huntStatus === '10') {
				update.probability = `${calculateBinomialProbability(methodId, encounters, shinyCharm)}%`;
				update.hadShinyCharm = shinyCharm;
			}
			const user = getCurrentUser();
			updateHuntInfo(user.uid, this.gameId, this.fileId, this.hunt.huntId, update, callback);
		},
		updateOdds() {
			const { methodId, encounters } = this.hunt;
			const newOdds = dynamicShinyOdds(methodId, encounters);
			if (newOdds) {
				const { ...method } = this.method; // making sure to remove any reference to the original
				delete method.name; // remove the name property for comparison
				delete method.gen; // remove the gen property for comparison
				if (!areSameOdds(method, newOdds)) {
					this.method = { ...newOdds, name : this.method.name };
				}
			}
		},
		onHuntSuccess() {

		}
	}
}
</script>

<style lang="scss" scoped>
	.strike {
		text-decoration: line-through;
	}
</style>